/**
 * 製品規格図用規格項目リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductSubgroupRevision from '@/models/entities/product-subgroup-revision';
import ProductStandardSpecLabel from '@/models/entities/product-standard-spec-label';
import SpecLabel from '@/models/entities/spec-label';
import SpecPrefix from '@/models/entities/spec-prefix';
import SpecSuffix from '@/models/entities/spec-suffix';

class ProductStandardSpecLabelRevision {
    product_standard_spec_label_revision_id;
    product_subgroup;
    standard_spec_label;
    spec_label;
    spec_prefix;
    spec_suffix;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_standard_spec_label_revision_id = properties.product_standard_spec_label_revision_id ?? null;
        this.product_subgroup = new ProductSubgroupRevision(properties.product_subgroup);
        this.standard_spec_label = new ProductStandardSpecLabel(properties.standard_spec_label);
        this.spec_label = new SpecLabel(properties.spec_label);
        this.spec_prefix = new SpecPrefix(properties.spec_prefix);
        this.spec_suffix = new SpecSuffix(properties.spec_suffix);
        this.priority = properties.priority ?? null;
    }
}

export default ProductStandardSpecLabelRevision;
