import Enum from './enum'

/**
 * 対象区分
 */
class TargetType extends Enum {
    static PRODUCT_GROUP = 1; // 製品グループ
    static SUBGROUP = 2; // サブグループ
    static PRODUCT = 3; // 製品

    static values() {
        return {
            [this.PRODUCT_GROUP]: '製品グループ',
            [this.SUBGROUP]: 'サブグループ',
            [this.PRODUCT]: '製品',
        }
    }
}

export default TargetType;
