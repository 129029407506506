/**
 * 製品商社別情報リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductRevision from '@/models/entities/product-revision';
import ProductRetailer from '@/models/entities/product-retailer';
import Retailer from '@/models/entities/retailer';

class ProductRetailerRevision {
    product_retailer_revision_id;
    product;
    product_retailer;
    retailer;
    is_retailer_selling;
    retailer_product_code;
    retailer_product_name;
    retailer_rate;
    retailer_price;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_retailer_revision_id = properties.product_retailer_revision_id ?? null;
        this.product = new ProductRevision(properties.product);
        this.product_retailer = new ProductRetailer(properties.product_retailer);
        this.retailer = new Retailer(properties.retailer);
        this.is_retailer_selling = properties.is_retailer_selling ?? 0;
        this.retailer_product_code = properties.retailer_product_code ?? null;
        this.retailer_product_name = properties.retailer_product_name ?? null;
        this.retailer_rate = properties.retailer_rate ?? null;
        this.retailer_price = properties.retailer_price ?? null;
    }
}

export default ProductRetailerRevision;
