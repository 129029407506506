/**
 * 製品製作図用規格値リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import InputType from '@/models/enums/input-type'
import ProductRevision from '@/models/entities/product-revision';
import ProductProductionSpecValue from '@/models/entities/product-production-spec-value';
import ProductProductionSpecLabelRevision from '@/models/entities/product-production-spec-label-revision';

class ProductProductionSpecValueRevision {
    product_production_spec_value_revision_id;
    product;
    production_spec_value;
    production_spec_label;
    spec_value;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_production_spec_value_revision_id = properties.product_production_spec_value_revision_id ?? null;
        this.product = new ProductRevision(properties.product);
        this.production_spec_value = new ProductProductionSpecValue(properties.production_spec_value);
        this.production_spec_label = new ProductProductionSpecLabelRevision(properties.production_spec_label);

        if (properties.spec_value) {
            //数値の場合
            if (this.production_spec_label.spec_label.input_type === InputType.INTEGER) {
                this.spec_value = parseInt(properties.spec_value);
            //小数点の場合
            } else if (
                this.production_spec_label.spec_label.input_type === InputType.DECIMAL_1
                || this.production_spec_label.spec_label.input_type === InputType.DECIMAL_2
            ) {
                this.spec_value = parseFloat(properties.spec_value);
            //文字列の場合
            } else {
                this.spec_value = properties.spec_value;
            }
        } else {
            this.spec_value = null; //初期値nullで送る
        }
    }
}

export default ProductProductionSpecValueRevision;
