import Enum from './enum'

/**
 * 編集タイプ
 */
class EditType extends Enum {
    static EDIT = 1; // 編集
    static IMPORT = 2; // インポート
    static UPDATE_MASTER = 3; // マスタ変更
    static ROLLBACK = 4; // ロールバック

    static values() {
        return {
            [this.EDIT]: '編集',
            [this.IMPORT]: 'インポート',
            [this.UPDATE_MASTER]: 'マスタ変更',
            [this.ROLLBACK]: 'ロールバック',
        }
    }
}

export default EditType;
