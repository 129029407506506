/**
 * 製品サブグループリビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductSubgroup from '@/models/entities/product-subgroup';
import ProductGroupRevision from '@/models/entities/product-group-revision';
import ProductSubgroupImageRevision from '@/models/entities/product-subgroup-image-revision';
import ProductSubgroupCatalogDrawingRevision from '@/models/entities/product-subgroup-catalog-drawing-revision';
import ProductSubgroupStandardDrawingRevision from '@/models/entities/product-subgroup-standard-drawing-revision';
import ProductSubgroupProductionDrawingRevision from '@/models/entities/product-subgroup-production-drawing-revision';
import ProductSubgroupCatalogPageRevision from '@/models/entities/product-subgroup-catalog-page-revision';
import ProductCatalogSpecLabelRevision from '@/models/entities/product-catalog-spec-label-revision';
import ProductStandardSpecLabelRevision from '@/models/entities/product-standard-spec-label-revision';
import ProductProductionSpecLabelRevision from '@/models/entities/product-production-spec-label-revision';
import ProductRevision from '@/models/entities/product-revision';

class ProductSubgroupRevision {
    product_subgroup_revision_id;
    product_group_revision;
    product_subgroup;
    product_subgroup_name;
    priority;
    is_on_catalog;
    is_price_on_catalog;
    images;
    catalog_drawings;
    standard_drawings;
    production_drawings;
    catalog_pages;
    catalog_spec_labels;
    standard_spec_labels;
    production_spec_labels;
    products;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_subgroup_revision_id = properties.product_subgroup_revision_id ?? null;
        this.product_group_revision = new ProductGroupRevision(properties.product_group_revision);
        this.product_subgroup = new ProductSubgroup(properties.product_subgroup);
        this.product_subgroup_name = properties.product_subgroup_name ?? null;
        this.priority = properties.priority ?? null;
        this.is_on_catalog = properties.is_on_catalog ?? 0;
        this.is_price_on_catalog = properties.is_price_on_catalog ?? 0;

        this.images = [];
        if ('images' in properties) {
            for (let image of properties.images) {
                this.images.push(new ProductSubgroupImageRevision(image));
            }
        }
        this.catalog_drawings = [];
        if ('catalog_drawings' in properties) {
            for (let catalog_drawing of properties.catalog_drawings) {
                this.catalog_drawings.push(new ProductSubgroupCatalogDrawingRevision(catalog_drawing));
            }
        }
        this.standard_drawings = [];
        if ('standard_drawings' in properties) {
            for (let standard_drawing of properties.standard_drawings) {
                this.standard_drawings.push(new ProductSubgroupStandardDrawingRevision(standard_drawing));
            }
        }
        this.production_drawings = [];
        if ('production_drawings' in properties) {
            for (let production_drawing of properties.production_drawings) {
                this.production_drawings.push(new ProductSubgroupProductionDrawingRevision(production_drawing));
            }
        }
        this.catalog_pages = [];
        if ('catalog_pages' in properties) {
            for (let catalog_page of properties.catalog_pages) {
                this.catalog_pages.push(new ProductSubgroupCatalogPageRevision(catalog_page));
            }
        }
        this.catalog_spec_labels = [];
        if ('catalog_spec_labels' in properties) {
            for (let catalog_spec_label of properties.catalog_spec_labels) {
                this.catalog_spec_labels.push(new ProductCatalogSpecLabelRevision(catalog_spec_label));
            }
        }
        this.standard_spec_labels = [];
        if ('standard_spec_labels' in properties) {
            for (let standard_spec_label of properties.standard_spec_labels) {
                this.standard_spec_labels.push(new ProductStandardSpecLabelRevision(standard_spec_label));
            }
        }
        this.production_spec_labels = [];
        if ('production_spec_labels' in properties) {
            for (let production_spec_label of properties.production_spec_labels) {
                this.production_spec_labels.push(new ProductProductionSpecLabelRevision(production_spec_label));
            }
        }
        this.products = [];
        if ('products' in properties) {
            for (let product of properties.products) {
                this.products.push(new ProductRevision(product));
            }
        }
    }
}

export default ProductSubgroupRevision;
