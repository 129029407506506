/**
 * 製品サブグループ画像リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductSubgroupRevision from '@/models/entities/product-subgroup-revision';
import ProductSubgroupImage from '@/models/entities/product-subgroup-image';

class ProductSubgroupImageRevision {
    product_subgroup_image_revision_id;
    product_subgroup;
    image;
    file_label;
    file_name;
    file_path;
    thumbnail_path;
    mime_type;
    file_size;
    file_url;
    thumbnail_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_subgroup_image_revision_id = properties.product_subgroup_image_revision_id ?? null;
        this.product_subgroup = new ProductSubgroupRevision(properties.product_subgroup);
        this.image = new ProductSubgroupImage(properties.image);
        this.file_label = properties.file_label;
        this.file_name = properties.file_name;
        this.file_path = properties.file_path;
        this.thumbnail_path = properties.thumbnail_path;
        this.mime_type = properties.mime_type;
        this.file_size = properties.file_size;
        this.file_url = properties.file_url;
        this.thumbnail_url = properties.thumbnail_url;
    }
}

export default ProductSubgroupImageRevision;
