/**
 * 編集履歴トラン エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmdHi } from '@/utilities/date-format';
import EditType from '@/models/enums/edit-type';
import ProductGroup from '@/models/entities/product-group';
import HistoryDetail from '@/models/entities/history-detail';
import User from '@/models/entities/user';

class History {
    history_id;
    product_group;
    revision;
    edit_datetime;
    user;
    edit_type;

    history_details;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.history_id = properties.history_id;
        this.product_group = new ProductGroup(properties.product_group);
        this.revision = properties.revision;
        this.edit_datetime = properties.edit_datetime;
        this.user = new User(properties.user);
        this.edit_type = properties.edit_type;

        this.details = [];
        if ('details' in properties) {
            for (let detail of properties.details) {
                this.details.push(new HistoryDetail(detail));
            }
        }
    }

    get edit_datetime_display() {
        return dateYmdHi(this.edit_datetime);
    }

    get edit_type_display() {
        return EditType.get(this.edit_type);
    }

    get edit_type_icon() {
        if (this.edit_type === EditType.EDIT) {
            return 'bi-pencil-square'
        }
        if (this.edit_type === EditType.IMPORT) {
            return 'bi-upload'
        }
        if (this.edit_type === EditType.UPDATE_MASTER) {
            return 'bi-gear'
        }
        if (this.edit_type === EditType.ROLLBACK) {
            return 'bi-skip-backward-fill';
        }

        return '';
    }
}

export default History;
