import Enum from './enum'

/**
 * 履歴詳細 タイプ
 */
class HistoryDetailType extends Enum {
    static CREATE = 1; // 追加
    static UPDATE = 2; // 更新
    static DELETE = 3; // 削除

    static values() {
        return {
            [this.CREATE]: '追加',
            [this.UPDATE]: '更新',
            [this.DELETE]: '削除',
        }
    }
}

export default HistoryDetailType;