/**
 * 製品画像リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductRevision from '@/models/entities/product-group-revision';
import ProductImage from '@/models/entities/product-group-image';

class ProductImageRevision {
    product_image_revision_id;
    product;
    product_image;
    file_label;
    file_name;
    file_path;
    thumbnail_path;
    mime_type;
    file_size;
    file_url;
    thumbnail_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_image_revision_id = properties.product_image_revision_id ?? null;
        this.product = new ProductRevision(properties.product);
        this.product_image = new ProductImage(properties.product_image);
        this.file_label = properties.file_label;
        this.file_name = properties.file_name;
        this.file_path = properties.file_path;
        this.thumbnail_path = properties.thumbnail_path;
        this.mime_type = properties.mime_type;
        this.file_size = properties.file_size;
        this.file_url = properties.file_url;
        this.thumbnail_url = properties.thumbnail_url;
    }
}

export default ProductImageRevision;
