/**
 * 製品サブグループカタログ掲載リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductSubgroupCatalogPage from '@/models/entities/product-group-catalog-page';
import ProductSubgroupRevision from '@/models/entities/product-group-revision';
import Catalog from '@/models/entities/catalog';

class ProductSubgroupCatalogPageRevision {
    product_subgroup_catalog_page_revision_id;
    product_subgroup_catalog_page;
    product_subgroup;
    catalog;
    chapter;
    section;
    sequence;
    page_number;
    catalog_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_subgroup_catalog_page_revision_id = properties.product_subgroup_catalog_page_revision_id ?? null;
        this.catalog_page = new ProductSubgroupCatalogPage(properties.catalog_page);
        this.product_subgroup = new ProductSubgroupRevision(properties.product_subgroup);
        this.catalog = new Catalog(properties.catalog);
        this.chapter = properties.chapter ?? null;
        this.section = properties.section ?? null;
        this.sequence = properties.sequence ?? null;
        this.page_number = properties.page_number ?? null;
        this.catalog_url = properties.catalog_url ?? null;
    }
}

export default ProductSubgroupCatalogPageRevision;
