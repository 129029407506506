/**
 * 製品規格図用規格値リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import InputType from '@/models/enums/input-type'
import ProductRevision from '@/models/entities/product-revision';
import ProductStandardSpecValue from '@/models/entities/product-standard-spec-value';
import ProductStandardSpecLabelRevision from '@/models/entities/product-standard-spec-label-revision';

class ProductStandardSpecValueRevision {
    product_standard_spec_value_revision_id;
    product;
    standard_spec_value;
    standard_spec_label;
    spec_value;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_standard_spec_value_revision_id = properties.product_standard_spec_value_revision_id ?? null;
        this.product = new ProductRevision(properties.product);
        this.standard_spec_value = new ProductStandardSpecValue(properties.standard_spec_value);
        this.standard_spec_label = new ProductStandardSpecLabelRevision(properties.standard_spec_label);

        if (properties.spec_value) {
            //数値の場合
            if (this.standard_spec_label.spec_label.input_type === InputType.INTEGER) {
                this.spec_value = parseInt(properties.spec_value);
            //小数点の場合
            } else if (
                this.standard_spec_label.spec_label.input_type === InputType.DECIMAL_1
                || this.standard_spec_label.spec_label.input_type === InputType.DECIMAL_2
            ) {
                this.spec_value = parseFloat(properties.spec_value);
            //文字列の場合
            } else {
                this.spec_value = properties.spec_value;
            }
        } else {
            this.spec_value = null; //初期値nullで送る
        }
    }
}

export default ProductStandardSpecValueRevision;
