/**
 * 製品リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmd } from '@/utilities/date-format';
import { dateYmdForInputDate } from '@/utilities/date-format';
import ProductSubgroupRevision from '@/models/entities/product-subgroup-revision';
import WholesaleRate from '@/models/entities/wholesale-rate';
import ProductImageRevision from '@/models/entities/product-image-revision';
import ProductProductionDrawingRevision from '@/models/entities/product-production-drawing-revision';
import ProductCatalogSpecValueRevision from '@/models/entities/product-catalog-spec-value-revision';
import ProductStandardSpecValueRevision from '@/models/entities/product-standard-spec-value-revision';
import ProductProductionSpecValueRevision from '@/models/entities/product-production-spec-value-revision';
import ProductRetailerRevision from '@/models/entities/product-retailer-revision';
import ProductComponentRevision from '@/models/entities/product-component-revision';
// import DisplayValueService from '@/models/services/display-value-service';


class ProductRevision {
    product_revision_id;
    product_id;
    product_subgroup_revision;
    product_code;
    public_type;
    availability;
    pending_date;
    priority;
    is_on_catalog;
    is_price_on_catalog;
    parts_number;
    model_number;
    description;
    description_en;
    note;
    note_en;
    production_type;
    is_each_time;
    regular_price;
    wholesale_rate;
    production_cost;
    production_hours;
    product_buffer;
    product_buffer_red;
    product_buffer_yellow;
    product_buffer_green;
    product_buffer_limit;
    field_1;
    field_2;
    field_3;

    product_group_name;
    joined_product_code;

    images;
    production_drawings;
    retailers;
    catalog_spec_values;
    standard_spec_values;
    production_spec_values;
    components;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_revision_id = properties.product_revision_id ?? null;
        this.product_id = properties.product_id ?? null;
        this.product_subgroup_revision = new ProductSubgroupRevision(properties.product_subgroup);
        this.product_code = properties.product_code;
        this.public_type = properties.public_type;
        this.availability = properties.availability;
        this.pending_date = dateYmdForInputDate(properties.pending_date);
        this.priority = properties.priority;
        this.is_on_catalog = properties.is_on_catalog ?? 0;
        this.is_price_on_catalog = properties.is_price_on_catalog ?? 0;
        this.parts_number = properties.parts_number ?? null;
        this.model_number = properties.model_number ?? null;
        this.description = properties.description ?? null;
        this.description_en = properties.description_en ?? null;
        this.note = properties.note ?? null;
        this.note_en = properties.note_en ?? null;
        this.production_type = properties.production_type ?? null;
        this.is_each_time = properties.is_each_time ?? 0;
        this.regular_price = properties.regular_price ?? null;
        this.wholesale_rate = new WholesaleRate(properties.wholesale_rate);
        this.production_cost = properties.production_cost ? parseInt(properties.production_cost, 10) : null;
        this.production_hours = properties.production_hours ? parseFloat(properties.production_hours) : null;
        this.product_buffer = properties.product_buffer ? parseFloat(properties.product_buffer) : null;
        this.product_buffer_red = properties.product_buffer_red ? parseFloat(properties.product_buffer_red) : null;
        this.product_buffer_yellow = properties.product_buffer_yellow ? parseFloat(properties.product_buffer_yellow) : null;
        this.product_buffer_green = properties.product_buffer_green ? parseFloat(properties.product_buffer_green) : null;
        this.product_buffer_limit = properties.product_buffer_limit ? parseInt(properties.product_buffer_limit, 10) : null;
        this.field_1 = properties.field_1 ?? null;
        this.field_2 = properties.field_2 ?? null;
        this.field_3 = properties.field_3 ?? null;

        this.product_group_name = properties.product_group_name;
        this.joined_product_code = properties.joined_product_code;

        this.images = [];
        if ('images' in properties) {
            for (let image of properties.images) {
                this.images.push(new ProductImageRevision(image));
            }
        }
        this.production_drawings = [];
        if ('production_drawings' in properties) {
            for (let production_drawing of properties.production_drawings) {
                this.production_drawings.push(new ProductProductionDrawingRevision(production_drawing));
            }
        }
        this.retailers = [];
        if ('retailers' in properties) {
            for (let product_retailer of properties.retailers) {
                this.retailers.push(new ProductRetailerRevision(product_retailer));
            }
        }
        this.catalog_spec_values = [];
        if ('catalog_spec_values' in properties) {
            for (let catalog_spec_value of properties.catalog_spec_values) {
                this.catalog_spec_values.push(new ProductCatalogSpecValueRevision(catalog_spec_value));
            }
        }
        this.standard_spec_values = [];
        if ('standard_spec_values' in properties) {
            for (let standard_spec_value of properties.standard_spec_values) {
                this.standard_spec_values.push(new ProductStandardSpecValueRevision(standard_spec_value));
            }
        }
        this.production_spec_values = [];
        if ('production_spec_values' in properties) {
            for (let production_spec_value of properties.production_spec_values) {
                this.production_spec_values.push(new ProductProductionSpecValueRevision(production_spec_value));
            }
        }
        this.components = [];
        if ('components' in properties) {
            for (let component of properties.components) {
                this.components.push(new ProductComponentRevision(component));
            }
        }
    }

    get regular_price_display() {
        if (!this.regular_price) {
            return '-'; //未入力
        }

        return '¥ ' + parseInt(this.regular_price, 10).toLocaleString();
    }

    get standard_spec_values_display() {
        if (!this.standard_spec_values.length) {
            return 'なし';
        }
        let values = this.standard_spec_values.slice(0, 2).map(standard_spec_value => {
            return standard_spec_value.full_spec_value;
        });

        return values.join(", ");
    }

    get pending_date_display() {
        if (!this.pending_date) {
            return '';
        }

        return dateYmd(this.pending_date);
    }

    // getValue(key, group = null) {
    //     //基本情報・価格情報
    //     if (key in this) {

    //         //enumの値等を日本語文字列に変換して返す
    //         return DisplayValueService.get(key, this[key]);

    //     // カタログ図規格項目
    //     } else if (key.indexOf('catalog_spec-') === 0) {
    //         //なかったら値無しで返す
    //         if (!this.catalog_spec_values.length) {
    //             return '-';
    //         }

    //         let result = key.split('-');
    //         let product_catalog_spec_label_revision_id = parseInt(result[1], 10);
    //         let catalog_spec_value = this.catalog_spec_values.find(value => {
    //             return value.catalog_spec_label.product_catalog_spec_label_revision_id === product_catalog_spec_label_revision_id;
    //         });
    //         if (!catalog_spec_value || !catalog_spec_value.spec_value) {
    //             return '-';
    //         }

    //         let prefix = catalog_spec_value.catalog_spec_label.spec_prefix.prefix_name ?? '';
    //         let suffix = catalog_spec_value.catalog_spec_label.spec_suffix.suffix_name ?? '';
    //         let value = catalog_spec_value.spec_value;

    //         return prefix + value + suffix;

    //     // 規格図規格項目
    //     } else if (key.indexOf('standard_spec-') === 0) {
    //         //なかったら値無しで返す
    //         if (!this.standard_spec_values.length) {
    //             return '-';
    //         }

    //         let result = key.split('-');
    //         let product_standard_spec_label_revision_id = parseInt(result[1], 10);
    //         let standard_spec_value = this.standard_spec_values.find(value => {
    //             return value.standard_spec_label.product_standard_spec_label_revision_id === product_standard_spec_label_revision_id;
    //         });
    //         if (!standard_spec_value || !standard_spec_value.spec_value) {
    //             return '-';
    //         }

    //         let prefix = standard_spec_value.standard_spec_label.spec_prefix.prefix_name ?? '';
    //         let suffix = standard_spec_value.standard_spec_label.spec_suffix.suffix_name ?? '';
    //         let value = standard_spec_value.spec_value;

    //         return prefix + value + suffix;

    //     // 製作図規格項目
    //     } else if (key.indexOf('production_spec-') === 0) {
    //         //なかったら値無しで返す
    //         if (!this.production_spec_values.length) {
    //             return '-';
    //         }

    //         let result = key.split('-');
    //         let product_production_spec_label_revision_id = parseInt(result[1], 10);
    //         let production_spec_value = this.production_spec_values.find(value => {
    //             return value.production_spec_label.product_production_spec_label_revision_id === product_production_spec_label_revision_id;
    //         });
    //         if (!production_spec_value || !production_spec_value.spec_value) {
    //             return '-';
    //         }

    //         let prefix = production_spec_value.production_spec_label.spec_prefix.prefix_name ?? '';
    //         let suffix = production_spec_value.production_spec_label.spec_suffix.suffix_name ?? '';
    //         let value = production_spec_value.spec_value;

    //         return prefix + value + suffix;

    //     // 商社別規格項目
    //     } else if (key.indexOf('retailer_') === 0) {
    //         //商社がなかったら値無しで返す
    //         if (!this.retailers.length) {
    //             return '-';
    //         }

    //         // 「retailer_1_retailer_product_code」のような key がくるので「retailer_product_code」を取り出す
    //         let array = key.split('_');
    //         let retailer_id = parseInt(array[1], 10);
    //         array.splice(0, 2);
    //         let preference_key = array.join('_');

    //         let product_retailer = this.retailers.find(product_retailer => {
    //             return product_retailer.retailer.retailer_id === retailer_id;
    //         });

    //         if (!product_retailer) {
    //             return '-';
    //         }

    //         //enumの値等を日本語文字列に変換して返す
    //         return DisplayValueService.get(preference_key, product_retailer[preference_key]);

    //     // 価格情報
    //     } else if (group === 'price') {
    //         //掛率記号
    //         if (key === 'wholesale_rate_id') {
    //             return DisplayValueService.get(key, this.wholesale_rate);
    //         //価格の場合は計算（'price'でなく'_price'でグレップ）
    //         if (preference_key.indexOf('_price') != -1) {
    //             let target_key = preference_key.replace('_price', ''); //「_price」を削除 →（例）distributor
    //             //率取得
    //             let rate = this.wholesale_rate[target_key + '_rate'];

    //             //定価取得
    //             let regular_price = parseInt(this.regular_price, 10);  //切り捨て
    //             if (!regular_price) {
    //                 return '-'; //未入力
    //             }
    //             if (!rate) {
    //                 return regular_price.toLocaleString();
    //             }

    //             return Math.floor(regular_price * (rate / 100)).toLocaleString(); //切り捨て
    //         //率の場合
    //         } else if (preference_key.indexOf('_rate') != -1) {
    //             return this.wholesale_rate[preference_key];
    //         }

    //         return '-';
    //     }

    //     //enumの値等を日本語文字列に変換して返す
    //     return DisplayValueService.get(key, this[key]);
    // }
}

export default ProductRevision;
