/**
 * 製品グループカタログ図リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductGroupRevision from '@/models/entities/product-group-revision';
import ProductGroupCatalogDrawing from '@/models/entities/product-group-catalog-drawing';

class ProductGroupCatalogDrawingRevision {
    product_group_catalog_drawing_revision_id;
    product_group;
    catalog_drawing;
    file_label;
    file_name;
    file_path;
    thumbnail_path;
    mime_type;
    file_size;
    file_url;
    thumbnail_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_group_catalog_drawing_revision_id = properties.product_group_catalog_drawing_revision_id ?? null;
        this.product_group = new ProductGroupRevision(properties.product_group);
        this.catalog_drawing = new ProductGroupCatalogDrawing(properties.catalog_drawing);
        this.file_label = properties.file_label;
        this.file_name = properties.file_name;
        this.file_path = properties.file_path;
        this.thumbnail_path = properties.thumbnail_path;
        this.mime_type = properties.mime_type;
        this.file_size = properties.file_size;
        this.file_url = properties.file_url;
        this.thumbnail_url = properties.thumbnail_url;
    }
}

export default ProductGroupCatalogDrawingRevision;
