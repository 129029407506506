/**
 * 製品グループ規格変更履歴リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmdForInputDate } from '@/utilities/date-format';
import ProductGroupRevision from '@/models/entities/product-group-revision';
import ProductGroupWorkflow from '@/models/entities/product-group-workflow';

class ProductGroupWorkflowRevision {
    product_group_workflow_revision_id;
    product_group;
    workflow;
    workflow_date;
    workflow_number;
    approved_status;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_group_workflow_revision_id = properties.product_group_workflow_revision_id ?? null;
        this.product_group = new ProductGroupRevision(properties.product_group);
        this.workflow = new ProductGroupWorkflow(properties.workflow);
        this.workflow_date = properties.workflow_date ? dateYmdForInputDate(properties.workflow_date) : null; //input-date用に変換
        this.workflow_number = properties.workflow_number ?? null;
        this.approved_status = properties.approved_status ?? null;
    }
}

export default ProductGroupWorkflowRevision;
