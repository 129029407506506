/**
 * 製品サブグループカタログ図用規格項目リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductSubgroupRevision from '@/models/entities/product-subgroup-revision';
import ProductCatalogSpecLabel from '@/models/entities/product-catalog-spec-label';
import SpecLabel from '@/models/entities/spec-label';
import SpecPrefix from '@/models/entities/spec-prefix';
import SpecSuffix from '@/models/entities/spec-suffix';

class ProductCatalogSpecLabelRevision {
    product_catalog_spec_label_revision_id;
    product_subgroup_revision;
    product_catalog_spec_label;
    spec_label;
    spec_prefix;
    spec_suffix;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_catalog_spec_label_revision_id = properties.product_catalog_spec_label_revision_id ?? null;
        this.product_subgroup_revision = new ProductSubgroupRevision(properties.product_subgroup_revision);
        this.product_catalog_spec_label = new ProductCatalogSpecLabel(properties.product_catalog_spec_label);
        this.spec_label = new SpecLabel(properties.spec_label);
        this.spec_prefix = new SpecPrefix(properties.spec_prefix);
        this.spec_suffix = new SpecSuffix(properties.spec_suffix);
        this.priority = properties.priority ?? null;
    }
}

export default ProductCatalogSpecLabelRevision;
