/**
 * 製品カタログ図用規格値リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import InputType from '@/models/enums/input-type'
import ProductRevision from '@/models/entities/product-revision';
import ProductCatalogSpecValue from '@/models/entities/product-catalog-spec-value';
import ProductCatalogSpecLabelRevision from '@/models/entities/product-catalog-spec-label-revision';

class ProductCatalogSpecValueRevision {
    product_catalog_spec_value_revision_id;
    product;
    catalog_spec_value;
    catalog_spec_label;
    spec_value;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_catalog_spec_value_revision_id = properties.product_catalog_spec_value_revision_id ?? null;
        this.product = new ProductRevision(properties.product);
        this.catalog_spec_value = new ProductCatalogSpecValue(properties.catalog_spec_value);
        this.catalog_spec_label = new ProductCatalogSpecLabelRevision(properties.catalog_spec_label);

        if (properties.spec_value) {
            //数値の場合
            if (this.catalog_spec_label.spec_label.input_type === InputType.INTEGER) {
                this.spec_value = parseInt(properties.spec_value);
            //小数点の場合
            } else if (
                this.catalog_spec_label.spec_label.input_type === InputType.DECIMAL_1
                || this.catalog_spec_label.spec_label.input_type === InputType.DECIMAL_2
            ) {
                this.spec_value = parseFloat(properties.spec_value);
            //文字列の場合
            } else {
                this.spec_value = properties.spec_value;
            }
        } else {
            this.spec_value = null; //初期値nullで送る
        }
    }
}

export default ProductCatalogSpecValueRevision;
