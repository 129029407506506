
import DisplayValueService from '@/models/services/display-value-service';

/**
 * 製品履歴用 値を表示用に変換サービス・・・まずvalue=nullをテキスト変換、null以外はDisplayValueServiceへ
 * 
 * 製品編集履歴 で使用
 */
class HistoryDisplayValueService extends DisplayValueService
{
    //keyをもとにenum値の場合はvalueを変換して返す
    static get(key, value) {
        if (null === value) {
            return '-';
        }
        
        //掛率: historyではvalue（オブジェクト）をそのまま返す
        if (key === 'wholesale_rate_id') {
            return value;
        }

        return super.get(key, value);
    }
}

export default HistoryDisplayValueService;