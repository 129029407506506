/**
 * 製品グループ動画リビジョン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ProductGroupRevision from '@/models/entities/product-group-revision';
import ProductGroupMovie from '@/models/entities/product-group-movie';

class ProductGroupMovieRevision {
    product_group_movie_revision_id;
    movie;
    product_group;
    movie_name;
    movie_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product_group_movie_revision_id = properties.product_group_movie_revision_id ?? null;
        this.movie = new ProductGroupMovie(properties.movie);
        this.product_group = new ProductGroupRevision(properties.product_group);
        this.movie_name = properties.movie_name ?? null;
        this.movie_url = properties.movie_url ?? null;
    }
}

export default ProductGroupMovieRevision;
