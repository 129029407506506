/**
 * 編集履歴詳細トラン エンティティ
 */
import { isNud } from '@/utilities/typing'
import History from '@/models/entities/history';
import TargetType from '@/models/enums/target-type';
import HistoryDetailType from '@/models/enums/history-detail-type';
import HistoryDisplayValueService from '@/models/services/history-display-value-service';

class HistoryDetail {
    history_detail_id;
    history;
    history_detail_type;
    target_type;
    target_key;
    target_label;
    before_value;
    after_value;
    // before_metadata_json;
    // after_metadata_json;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.history_detail_id = properties.history_detail_id;
        this.history = new History(properties.history);
        this.history_detail_type = properties.history_detail_type;
        this.target_type = properties.target_type;
        this.target_key = properties.target_key;
        this.target_code = properties.target_code;
        this.target_label = properties.target_label;

        //enumの値等を日本語文字列に変換して返す
        this.before_value = properties.before_value;
        this.after_value = properties.after_value;

        // this.before_metadata_json = properties.before_metadata_json;
        // this.after_metadata_json = properties.after_metadata_json;
    }

    get target_type_display() {
        return TargetType.get(this.target_type);
    }

    get before_value_display() {
        return HistoryDisplayValueService.get(this.target_key, this.before_value);
    }

    get after_value_display() {
        return HistoryDisplayValueService.get(this.target_key, this.after_value);
    }

    get history_detail_type_display() {
        return HistoryDetailType.get(this.history_detail_type);
    }
}

export default HistoryDetail;
